import '@hotwired/turbo-rails'
import '../controllers'

document.addEventListener("turbo:load", function() {
  const params = new URLSearchParams(window.location.search);
  if (params.get('scroll_to')) {
    const element = document.querySelector(`a[name='${params.get('scroll_to')}']`);
    if (element) {
      element.scrollIntoView();
      const sticky = document.querySelector(".sequence-header")
      const stickyHeight = sticky.scrollHeight;
      if (sticky) window.scrollBy(0, stickyHeight * -1);
    }
  }
});
